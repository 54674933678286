import Head from 'next/head';
import { useRouter } from 'next/router';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { ICookiesPopupProps } from '../components/common/CookiesPopUp';
import { IContactFormValues } from '../components/common/forms/ContactForm';
import { IFooterProps } from '../components/common/layout/Footer';
import Layout from '../components/common/layout/Layout';
import { INavBarProps } from '../components/common/layout/NavBar';
import Contact, { IContactProps } from '../components/contact-us/Contact';
import { useAppSelector } from '../hooks';
import { IContactMethod, IContactUsForm, ICustomErrorPage404, ILayoutPageProps, ILog404 } from '../models/strapi.models';
import { fetchStrapi, postStrapi, putStrapi } from '../services/api.service';
import { logError } from '../services/log.service';
import { buildApiKey, getContactMethods, populateCookiesPopUpProps, populateFooterProps, populateNavBarProps } from '../shared/useCommon';
import { getContentLanguageCode, getTranslation } from '../shared/useUtils';
import { NextPageWithLayout } from './_app';

interface ICustom404PageProps extends ILayoutPageProps {
  heading: string;
  formSuccessMessage: string;
  formErrorMessage: string;
  contactMethods: IContactMethod[];
  contactUsForm: IContactUsForm;
}

interface ICustom404PageState {
  submitResultType: 'success' | 'error' | undefined;
  error404SentId: number | undefined;
}

const Custom404Page: NextPageWithLayout<ICustom404PageProps> = (props: ICustom404PageProps) => {
  const router = useRouter();
  const user = useAppSelector((state) => state.auth.user);
  const initialPageState: ICustom404PageState = {
    submitResultType: undefined,
    error404SentId: undefined,
  };
  const [state, setState] = useState(initialPageState);

  const createError = useCallback(async () => {
    if (state.error404SentId) {
      return;
    }

    try {
      const payload: { data: ILog404 } = {
        data: {
          url: window.location.href,
          referrerUrl: document.referrer,
          timestamp: new Date(),
          user: !!user ? user.id : undefined,
          userAgent: navigator.userAgent,
        },
      };

      const result: any = await postStrapi<{ data: ILog404 }>(`/log-404s`, payload);
      const currentState = { ...state };
      currentState.error404SentId = result.data.id;
      setState(currentState);
    } catch (error) {
      logError(['Error sending 404 error', error]);
    }
  }, [state, user]);

  const updateError = useCallback(async () => {
    if (!state.error404SentId) {
      return;
    }

    try {
      const payload: { data: ILog404 } = {
        data: {
          url: window.location.href,
          referrerUrl: document.referrer,
          timestamp: new Date(),
          user: !!user ? user.id : undefined,
          userAgent: navigator.userAgent,
        },
      };

      await putStrapi<{ data: ILog404 }>(`/log-404s/${state.error404SentId}`, payload);
    } catch (error) {
      logError(['Error sending 404 error', error]);
    }
  }, [state.error404SentId, user]);

  const submit = async (formData: IContactFormValues) => {
    const payload = {
      data: {
        ...formData,
        sourceUrl: window.location.href,
      },
    };

    try {
      await postStrapi<any>(`/contact-submissions`, payload);
      const currentState = { ...state };
      currentState.submitResultType = 'success';
      setState(currentState);
    } catch (error) {
      const currentState = { ...state };
      currentState.submitResultType = 'error';
      setState(currentState);
      logError(['Error submitting contact form', error]);
    }
  };

  const contactProps: IContactProps = {
    contactMethods: props.contactMethods.map((x) => ({
      type: x.type,
      label: getTranslation(x.labelTranslations, router.locale || 'en').text as string,
      value: x.valueTranslations && x.valueTranslations.length > 0 ? (getTranslation(x.valueTranslations, router.locale || 'en').text as string) : undefined,
    })),
    titleTagName: 'h2',
    formLabels: {
      title: props.contactUsForm.title,
      subTitle: props.contactUsForm.subTitle,
      name: props.contactUsForm.nameLabel,
      email: props.contactUsForm.emailLabel,
      message: props.contactUsForm.messageLabel,
      submit: props.contactUsForm.submitLabel,
      submitSuccessMessage: props.formSuccessMessage,
      submitErrorMessage: props.formErrorMessage,
    },
    onSubmit: submit,
    submitResultType: state.submitResultType,
  };

  useEffect(() => {
    if (!!user) {
      updateError();
    } else {
      createError();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <div className="page-container">
      <Head>
        <title>{props.meta.title}</title>
        <meta name="robots" content="noindex,nofollow" />
        <meta name="googlebot" content="noindex,nofollow" />
      </Head>

      <div className="page-content-section">
        <div className="page-content-block">
          <ReactMarkdown>{props.heading}</ReactMarkdown>
        </div>
      </div>

      <div className="page-content-section is-narrower">
        <div className="page-content-block">
          <Contact {...contactProps} />
        </div>
      </div>
    </div>
  );
};

Custom404Page.getLayout = function getLayout(page: ReactElement) {
  return (
    <Layout
      locale={page.props.locale}
      navBarProps={page.props.navBarProps}
      cookiesPopUpProps={page.props.cookiesPopUpProps}
      footerProps={page.props.footerProps}
      contentLanguageCode={getContentLanguageCode(page.props.locale)}>
      {page}
    </Layout>
  );
};

export async function getStaticProps(context: any): Promise<{ props: ICustom404PageProps }> {
  const pageUrlParams = {
    populate: '*',
  };

  const page = (await fetchStrapi<any>(`/custom-error-page-404`, pageUrlParams, buildApiKey)).data.attributes as ICustomErrorPage404;
  const contactMethods = await getContactMethods();
  const navBarProps: INavBarProps = await populateNavBarProps(context.locale);
  const cookiesPopUpProps: ICookiesPopupProps = await populateCookiesPopUpProps(context.locale);
  const footerProps: IFooterProps = await populateFooterProps(context.locale);

  const props: ICustom404PageProps = {
    // Layout
    navBarProps,
    cookiesPopUpProps,
    footerProps,
    // Page
    locale: context.locale,
    meta: getTranslation(page.meta, context.locale),
    heading: getTranslation(page.heading, context.locale || 'en').text as string,
    formSuccessMessage: getTranslation(page.formSuccessMessage, context.locale || 'en').text as string,
    formErrorMessage: getTranslation(page.formErrorMessage, context.locale || 'en').text as string,
    contactMethods,
    contactUsForm: getTranslation(page.contactUsForm, context.locale || 'en'),
  };

  return {
    props,
  };
}

export default Custom404Page;
